export class PayloadUtils {
	static filterFalsyValues<T>(obj: Partial<T>): Partial<T> {
		return Object.fromEntries(
			Object.entries(obj).filter(([_, v]) => v !== null)
		) as Partial<T>;
	}
	static toBoolean(value: any): boolean {
		return (
			value === true || value === 1 || value === "1" || value === "true"
		);
	}
}
