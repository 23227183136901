import { inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IValueLabel } from "@sportyano/shared/models/interfaces/value-label.interface";

export class DateTimeUtils {
	static convertTo24Hour(time: string): string {
		const [timePart, modifier] = time.split(" ");

		let hours = parseInt(timePart, 10);
		let minutes = "00";

		if (modifier === "PM" && hours !== 12) {
			hours += 12;
		} else if (modifier === "AM" && hours === 12) {
			hours = 0;
		}

		// Format hours to two digits
		const hoursFormatted = hours.toString().padStart(2, "0");

		return `${hoursFormatted}:${minutes}:00`;
	}

	static convertTo12HourFormat(
		time24: string,
		lang: "en" | "ar" = "en"
	): string {
		if (!time24) return time24;

		const [hours, minutes] = time24.split(":");
		let hour = parseInt(hours, 10);
		const isPM = hour >= 12;

		// Convert to 12-hour format
		if (hour > 12) {
			hour -= 12;
		} else if (hour === 0) {
			hour = 12; // Midnight (00:00) is 12 AM
		}

		const period = isPM
			? lang == "en"
				? "PM"
				: "مساءً"
			: lang == "en"
			? "AM"
			: "صباحاً";
		return `${hour}:${minutes} ${period}`;
	}

	
	static generateTimeHours(): string[] {
		const times: string[] = [];

		// Generate times from 01:00 to 23:00
		for (let hour = 1; hour < 24; hour++) {
			const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
			times.push(`${formattedHour}:00`);
		}

		// Move "00:00" to the end
		times.push("00:00");

		return times;
	}

	static todayDate(format: "full" | "short" = "full"): Date | string {
		const date = new Date();

		if (format === "full") {
			return date;
		} else {
			const day = ("0" + date.getDate()).slice(-2);
			const month = ("0" + (date.getMonth() + 1)).slice(-2);
			const shortYear = date.getFullYear().toString().slice(-2);
			return `${day}/${month}/${shortYear}`;
		}
	}

	static generateTimeArray(range: { from: number; to: number }): string[] {
		const times: string[] = [];
		for (let hour = range.from; hour <= range.to; hour++) {
			const formattedHour = hour.toString().padStart(2, "0");
			times.push(`${formattedHour}:00`);
		}
		return times;
	}

	static generateTimeArrayFromString(range: {
		from: string;
		to: string;
	}): string[] {
		const times: string[] = [];
	
		// Check if range and its properties are defined
		if (!range || !range.from || !range.to) {
			return times; // Return an empty array if range is invalid
		}
	
		// Split the "HH:MM" format into hours
		let [fromHour] = range.from.split(":").map(Number);
		const [toHour] = range.to.split(":").map(Number);
	
		// Ensure the hours are valid numbers
		if (isNaN(fromHour) || isNaN(toHour)) {
			console.error("Invalid time format:", range);
			return times; // Return an empty array if the time format is invalid
		}
	
		// Handle the case where fromHour is greater than toHour (crossing midnight)
		if (fromHour > toHour) {
			// Add hours from 'fromHour' to 23:00 (end of day)
			for (; fromHour <= 23; fromHour++) {
				const formattedHour = fromHour.toString().padStart(2, "0");
				times.push(`${formattedHour}:00`);
			}
	
			// Reset fromHour to 0 (midnight) and loop until 'toHour'
			fromHour = 0;
		}
	
		// Add hours from 'fromHour' to 'toHour'
		for (; fromHour <= toHour; fromHour++) {
			const formattedHour = fromHour.toString().padStart(2, "0");
			times.push(`${formattedHour}:00`);
		}
	
		return times;
	}
	
	static calculateEndDateByWeeks(
		startDate: string,
		durationWeek: number
	): Date {
		const start = new Date(startDate);
		const endDate = new Date(start);

		// Calculate the end date by adding the duration in weeks
		endDate.setDate(start.getDate() + durationWeek * 7);

		return endDate;
	}

	static dateAfterMonths(months: number): Date {
		const today = new Date();
		const futureDate = new Date(today);
		futureDate.setMonth(today.getMonth() + months);
		return futureDate;
	}

	static dateAfterWeeks(weeks: number): Date {
		const today = new Date();
		const futureDate = new Date(today);
		futureDate.setDate(today.getDate() + weeks * 7);
		return futureDate;
	}
	
	static timeStringToMinutes(time: string): number {
		const [hours, minutes] = time.split(":").map(Number);

		// Treat "00:00" as "24:00" for validation purposes
		if (hours === 0 && minutes === 0) {
			return 24 * 60; // 1440 minutes, equivalent to 24:00
		}

		return hours * 60 + minutes;
	}

	static secondsToMinSec(seconds: number): string {
		const minutes = Math.floor(seconds / 60);
		const secs = Math.floor(seconds % 60);

		// Format minutes and seconds to two digits
		return `${minutes.toString().padStart(2, "0")}:${secs
			.toString()
			.padStart(2, "0")}`;
	}

	static weekDays(): Array<IValueLabel<number>> {
		return [
			{
				value: 1,
				label: "account.academy_programs_schedule.week_days.Sunday",
			},
			{
				value: 2,
				label: "account.academy_programs_schedule.week_days.Monday",
			},
			{
				value: 3,
				label: "account.academy_programs_schedule.week_days.Tuesday",
			},
			{
				value: 4,
				label: "account.academy_programs_schedule.week_days.Wednesday",
			},
			{
				value: 5,
				label: "account.academy_programs_schedule.week_days.Thursday",
			},
			{
				value: 6,
				label: "account.academy_programs_schedule.week_days.Friday",
			},
			{
				value: 7,
				label: "account.academy_programs_schedule.week_days.Saturday",
			},
		];
	}

	static weekDaysMap(): Map<number, IValueLabel<number>> {
		// Convert array to Map
		return new Map(this.weekDays().map((day) => [day.value, day]));
	}
}
